@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root {
  // --theme-color-1: white;
  // --theme-color-2: #15A1b1;
  // --theme-color-3: #939598;
  // --theme-color-4: #3A3A3C;
  // --theme-color-5: #e6e6e6;
  --theme-color-1: theme('colors.theme1');
  --theme-color-2: theme('colors.theme2');
  --theme-color-3: theme('colors.theme3');
  --theme-color-4: theme('colors.theme4');
  --theme-color-5: theme('colors.theme5');
}

.theme-c1 {
  color: var(--theme-color-1);
}

.theme-c2 {
  color: var(--theme-color-2);
}

.theme-c3 {
  color: var(--theme-color-3);
}

.theme-c4 {
  color: var(--theme-color-4);
}

.theme-c5 { color:var(--theme-color-5); }

.theme-b1 {
  background-color: var(--theme-color-1);
}

.theme-b2 {
  background-color: var(--theme-color-2);
}

.theme-b3 {
  background-color: var(--theme-color-3);
}

.theme-b4 {
  background-color: var(--theme-color-4);
}

.theme-b5 { background-color:var(--theme-color-5); }

html,
body {
  font-family: 'Lato', sans-serif;
  @apply font-normal overflow-x-hidden text-base p-0 m-0 antialiased theme-b1 theme-c4;

  &.no-scroll {
    @apply overflow-hidden;
  }
}

p {
  @apply mb-4;
}

a {
  @apply text-white;
}

* {
  box-sizing: border-box;
}

ul {
  @apply list-disc pt-3 pl-6;
}

.bebas {
  font-family: 'Bebas Neue', cursive;
}

.bgWrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
}

/* MENU */

.mainNav {
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);

  @apply h-20 fixed top-0 left-0 right-0 animated-slow flex items-center text-base;
  @apply hidden lg:block;

  &.floating {
    // border-bottom: 2px solid var(--theme-color-4);
    @apply theme-b1 shadow-md;

    .Logo path {
      fill: var(--theme-color-4);
    }
  }

  &.navHidden {
    top: -100px !important;
    opacity: 1;
  }

  .navBar {
    @apply flex h-full items-center font-normal p-0 m-0;

    li,
    li>div {
      @apply flex h-full items-center;
    }

    button,
    a {
      @apply h-full flex justify-start items-center px-6 animated theme-c4 whitespace-nowrap font-bold;

      svg {
        @apply w-4 h-4 theme-c2 ml-3;
      }
    }
    
    button:hover,
    a:hover,
    button.isOpen,
    a.isOpen,
    button.active,
    a.active {
      @apply theme-b2 theme-c1;

      svg {
        @apply theme-c1;
      }
    }

    .dropdown-panel {
      min-width: 255px;

      @apply absolute z-10 top-full left-0 animated shadow-xl;

      &-inner {
        padding-top:1px;

        @apply gap-0;

        a {
          border-color:var(--theme-color-2);
          background-color: rgba(255, 255, 255, 0.95);
          margin-bottom:1px;
          @apply py-2 theme-c4 border-l-8 w-full;

          &.active,
          &:hover {
            @apply theme-b2 theme-c1;
          }
        }

      }

      .dropdown-panel {
        top:-1px;
        right:calc(-100% - 1px);
        @apply left-auto;
      }
    }
  }

  .contact-btn {
    @apply h-full flex justify-start items-center px-6 animated theme-c1 theme-b2 flex-nowrap whitespace-nowrap font-medium;

    svg {
      @apply w-4 h-4 theme-c1 mr-3;
    }

    &:hover {
      @apply theme-b4 theme-c1; 

      svg {
        @apply theme-c1;
      }
    }
  }

  .btn-shine {
    @apply hidden xl:flex;
  }
}

/* MOBILE MENU */

.mainNavMobile {
  z-index: 1004;
  @apply theme-b1 h-20 fixed top-0 left-0 right-0 px-6 animated-slow flex items-center text-base;
  @apply block lg:hidden;

  &.navHidden {
    top: -100px !important;
    opacity: 1;
  }

  &.floating {
    @apply theme-b1 shadow;

    .Logo path {
      fill: var(--theme-color-4);
    }

    .navBar {

      button,
      a {
        @apply theme-c1;
      }

      button:hover,
      a:hover,
      button.isOpen {
        @apply theme-b2 text-white;
      }

      .dropdown-panel {
        @apply theme-b2;

        &-inner {
          @apply gap-0;

          a {
            @apply py-2 text-white;

            &:hover {
              @apply theme-b4;
            }
          }

        }
      }


    }


  }

}

.burger {
  position: fixed;
  right: 25px;
  z-index: 99999;
  // top: -75px;
  top: 25px;
  @apply animated-slow flex lg:hidden #{!important};

  &.navHidden {
    top:-75px;
  }

  &.btn_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    transition: .5s ease;

    &:focus {
      outline: none;
    }

    .btn_menu__bars {
      display: block;
      position: relative;
      width: 50px;
      height: 3px;
      @apply theme-b4;
      transition: .5s;

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        @apply theme-b4;
        transition: .5s;
      }

      &:before {
        transform: translate(0, -12px);
      }

      &:after {
        transform: translate(0, 12px);
      }
    }


  }

  &.menu_open {

    top: 25px;

    .btn_menu__bars {
      background-color: transparent;

      &:before {
        transform: rotate(45deg);
        background-color: var(--theme-color-1);
      }

      &:after {
        transform: rotate(-45deg);
        background-color: var(--theme-color-1);
      }
    }
  }
}

.isTop {
  .burger {
    top: 25px;

    // .btn_menu__bars {
    //   background-color: var(--theme-color-4);

    //   &:before,
    //   &:after {
    //     background-color: var(--theme-color-4);
    //   }
    // }

    // &.menu_open {
    //   .btn_menu__bars {
    //     background-color: transparent;
    //   }
    // }
  }
}

.mobileMenuShow {
  .burger {
    top: 25px;
  }
}

.mobileOffCanvas {
  height: 100%;
  position: fixed;
  z-index: 99998;
  top: 0;
  right: 0;
  transition: 0.5s;
  width: 100%;
  // -webkit-transform: translateX(0);
  transform: translateX(0);
  @apply flex justify-end block lg:hidden translate-x-full;

  .mobileMenu {
    @apply theme-b2 shadow-xl z-20 h-full w-full sm:w-96;

    .mobileMenuContainer {
      @apply pt-20 flex h-full overflow-y-auto;

      .navBar {
        @apply m-0 p-0 list-none px-8 py-8;

        a,
        a.active {
          @apply font-normal text-xl theme-c1 mb-6 flex items-center;

          svg {
            @apply w-4 h-4 theme-c1 ml-3;
          }
        }

        .dropdown-panel-inner {
          @apply pl-8 pb-6;

          a {
            @apply mb-2;
          }
        }
      }

    }

    .navBar {
      @apply my-10 px-10;
    }

    .dropdown-toggle {
      @apply w-8;
      .dropdown-icon-down,
      .dropdown-icon-up {
        @apply text-white mt-2 w-4;
      }
    }

  }
}

.mobileCanvasOverlay {
  left: 0;
  top: 0;
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 99997;
  @apply theme-b1 opacity-50;
  @apply hidden lg:hidden #{!important};
}

.mobileMenuOpened {

  .mobileCanvasOverlay {
    @apply block lg:hidden #{!important};
  }

  .mobileOffCanvas {
    @apply translate-x-0;
  }
}

/* GLOBAL */

.animated-slow {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.animated {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.card {
  @apply shadow h-full animated font-normal text-sm border rounded-lg theme-c4 theme-b1;

  .image {
    @apply p-6 bg-white rounded-t-lg;
  }

  .cardContent {
    @apply p-6;
  }

  .card-btn {
    @apply theme-b2 py-3 px-6 text-white text-xl animated inline-flex items-center bebas;
      
    &:hover {
      @apply theme-b3 theme-c4;

      path {
        fill:var(--theme-color-4);
      }
    }
  }

  &:hover {
    @apply shadow-2xl cursor-pointer;
  }
}

.swiper-button-prev,
.swiper-button-next {
  @apply theme-b4 rounded-full w-9 h-9 #{!important};
}

.swiper-button-next:after {
  @apply absolute -top-2;
  content: url('/mdi-chevron-right.svg') !important;
}

.swiper-button-prev:after {
  @apply absolute -top-2;
  content: url('/mdi-chevron-left.svg') !important;
}

.btn {
  @apply py-2 px-6 animated text-sm shadow hover:shadow-xl theme-c1 theme-b4;

  &:hover {
    @apply theme-b1 theme-c4;
  }
}

.btn-light {
  @apply font-normal border theme-c4;
  border-color: var(--theme-color-4);
  background-color: transparent;

  &:hover {
    @apply theme-c4 theme-b1;
    background-color: white;
  }
}

.btn-big {
  @apply text-2xl font-normal;
}

.rowFooter {
  @apply bg-slate-800
}

/* HOMEPAGE */
.homepage {

  #riga1 {
    @apply pt-0;

    .videoPlaceholder {
      @apply h-screen relative flex justify-end items-center mb-32 md:mb-0;

      .social {
        @apply flex flex-col z-30 pr-8 absolute;
    
        path {
          @apply animated;
        }

        a{
          @apply mb-8;

          &:hover {
            path {
              fill: var(--theme-color-2);
            }
          }
        }
      }
    }

    video {
      @apply object-cover w-screen h-screen absolute top-0 left-0 z-10;
    }

    .videoContainer {
      height: calc(100vh - 80px);

      @apply w-screen absolute bottom-0 left-0 z-20;
    }

    .swiper-slide {
      @apply px-0;

      .captionContainer {
        @apply container mx-auto relative md:grid md:grid-cols-2 gap-12 items-center p-0 text-center md:text-left px-0;

        .caption {
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 0.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;

          background-color:rgba(62,62,62,0.64);
          min-height:230px;

          @apply opacity-0 -translate-x-full mb-0 relative py-14 pl-0 md:pl-4 lg:pl-0;

          &:before {
            content:'';
            background-color:rgba(62,62,62,0.64);
            @apply block absolute right-full top-0 w-screen h-full;
          }

          p {
            @apply text-xl lg:text-4xl p-0 m-0 font-normal theme-c2 bebas;
          }

          h4 {
            @apply relative text-4xl lg:text-5xl p-0 m-0 theme-c1 bebas;
          }
        }

        .img-container {
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 0.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          transition-delay: 300ms;

          @apply opacity-0 translate-x-full;
        }
      }

      &.swiper-slide-active {
        .caption {
          @apply opacity-100 translate-x-0;
        }

        .img-container {
          @apply opacity-100 translate-x-0;
        }
      }
    }

    .img-container {
      @apply animated;
    }

    .slide {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100vh - 80px);
      @apply flex items-end;
    }

    .custom-pagination {
      @apply absolute bottom-7 w-full md:w-1/2 flex justify-evenly pr-4 lg:pr-12 pl-6 z-10;
  
      .swiper-pagination-bullet {
        background-color:rgba(217,217,217,0.67);
        @apply rounded-none flex-1;
      }
  
      .swiper-pagination-bullet-active {
        @apply theme-b2;
      }
    }
   
    .checkContainer, .joinContainer {
      min-height:230px;
      @apply bg-theme6/[0.5] hidden md:flex absolute bottom-0 right-0 w-full md:w-1/4 justify-center items-center flex-col z-30 py-4;
      
      h4 {
        @apply bebas text-2xl lg:text-4xl theme-c1 mb-0;
      }

      p {
        @apply p-0 m-0 leading-tight text-center;
      }

      span {
        @apply theme-c1 bebas block mt-2;
      }

      .checkContainerLink, .joinContainerLink {
        @apply flex flex-col items-center animated;

        svg {
          @apply w-8;
        }

        &:hover {
          h4 {
            @apply theme-c2 animated;
          }
          path {
            @apply  animated fill-theme2;
          }
        }
      }
    }

    .joinContainer {
      @apply right-1/4 bg-theme5/[0.5] text-theme4;

      .joinContainerLink {  
        // @apply text-theme2;

        svg {
          @apply fill-theme4;
        }
  
        a, h4 {
          @apply text-theme4;
        }
      }

    }

    .checkContainer_mobile, .joinContainer_mobile {
      min-height:100px;

      @apply theme-b2 flex md:hidden absolute  left-0 w-full justify-center items-center flex-col py-4;
      
      h4 {
        @apply bebas text-2xl lg:text-4xl theme-c1 mb-0;
      }

      p {
        @apply p-0 m-0 leading-tight text-center;
      }

      span {
        @apply theme-c1 bebas block mt-2;
      }

      svg {
        @apply w-8;
      }

      .checkContainerLink {
        @apply flex flex-col items-center animated;

        &:hover {
          h4 {
            @apply theme-c2 animated;
          }
          path {
            @apply  animated;
            fill:var(--theme-color-2);
          }
        }
      }

      .joinContainerLink {  

        @apply flex flex-col items-center animated;

        svg {
          @apply fill-theme4;
        }
  
        a, h4 {
          @apply text-theme4;
        }
      }
    }

    .checkContainer_mobile {
      @apply top-[calc(100%+100px)];
    }

    .joinContainer_mobile {
      @apply top-full bg-theme5/[0.5] text-theme4;
    }
  }

  #riga2 {
    .text {
      @apply flex items-center order-1 lg:order-2;
    }  
    .player-wrapper {
      @apply  order-2  lg:order-1;
    }  
  }

  
  #riga2, #riga4 {
    @apply pt-24 lg:pt-16 pb-16 theme-b5;

    .grid {
      @apply gap-12;
    }

    .text {
      @apply flex items-center;

      h4 {
        @apply bebas theme-c2 text-4xl lg:text-5xl mb-8;
      }
    }
  }

  #riga3 {
    h2 {
      @apply py-12 text-5xl text-center bebas;
    }
  }

  #riga5 {
    @apply py-16 theme-b4;

    .grid {
      @apply gap-12;
    }

    .text {
      @apply flex items-center theme-c1  order-1 lg:order-2;

      h4 {
        @apply bebas theme-c2 text-4xl lg:text-5xl mb-8;
      }
    }

    .player-wrapper {
      @apply  order-2 lg:order-1;
    }
    
  }

  #map-title {
    @apply py-16 theme-b5;

    h4 {
      @apply bebas theme-c2 text-4xl lg:text-5xl text-center;
    }
  }

  .mapOverlay {
    z-index:401;
    @apply absolute top-0 left-0 w-full h-full pointer-events-none;

    .mapOverlay_container {
      @apply flex items-center container mx-auto h-full;

      .mapOverlayBox {
        background-color:rgba(20,166,166,0.5);
        @apply self-end mb-14 p-12 theme-c1;

        h3 {
          @apply bebas theme-c1 text-4xl text-left mb-8;
        }
      }
    }
  }
}

main.landing {

  @apply py-0 lg:py-24 mt-20 lg:mt-24;

  #card-group-intro {
    
    & > div:nth-child(odd) {
      .card {
        .image {
          @apply lg:order-1;
        }
        .cardContent {
          @apply lg:order-2;
        }
      }
    }
    
    & > div:nth-child(even) {
      .card {
        .image {
          @apply lg:order-2;
        }
        .cardContent {
          @apply lg:order-1;
        }
      }
    }
    
    .card {
      @apply shadow-none border-0 rounded-none md:grid grid-cols-3 lg:grid-cols-2 gap-0 mb-24 lg:mb-0 items-center;

      &:hover {
        @apply shadow-none cursor-auto;
      }

      .image {
        @apply m-0 p-0 col-span-1;
      }

      .cardContent {
        @apply text-left px-0 md:px-16 col-span-2  lg:col-span-1;

        h3 {
          @apply text-4xl theme-c2 text-left mb-8 bebas;
        }
      }

      .card-btn {
        @apply inline-block theme-b2 bebas text-base theme-c1 border-0 mt-8;

        &:hover {
          @apply theme-b4;
        }
      }
      
    }

  }

  #card-group-hotels {
    @apply gap-8;

    .card {
      @apply border-0 p-0 rounded-none shadow-none;
    } 
  }

}

h1, h2 {
  @apply text-5xl lg:text-7xl theme-c2 bebas mb-8;
}

h3, h4 {
  @apply text-3xl lg:text-5xl theme-c2 bebas mb-8;
}

h5, h6 {
  @apply text-xl lg:text-2xl theme-c2 bebas mb-8;
}

.griglia {
  @apply block lg:grid;
  & > div {
    @apply  mb-8 lg:mb-0;
  }
}

main section:not(#map-row) a:not(.card-btn):not(.externalBtn) {
  @apply theme-c2;
}

main.innerpage {
  @apply pb-24;

  a {
    @apply theme-c2;
  }


  section {
    @apply mb-24;
  }

  #slidetesto {
    .griglia {
      @apply flex flex-col lg:grid;

      & > div:nth-child(2) {
        @apply order-1 lg:order-2
      }
      & > div:nth-child(1) {
        @apply order-2 lg:order-1
      }
    }
  }

  #riga2 {
    @apply mb-8;
  }

  #noheader {
    @apply mt-48;
  }

  #introslide {
    @apply aspect-square mt-20 md:mt-0 md:aspect-auto md:h-screen w-full relative;

    .swiper {
      @apply aspect-square md:aspect-auto md:h-screen w-full;

      .swiper-slide {
        @apply bg-cover bg-no-repeat bg-center;
      }
    }

    .captionWrapper {

      .captionContainer {
        @apply container mx-auto relative md:grid md:grid-cols-2 gap-0 lg:gap-12 items-center text-center md:text-left;

        .caption {
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 0.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          background-color:rgba(62,62,62,0.64);

          @apply mb-0 relative z-10 p-0 py-14 md:p-14 pl-4 lg:pl-0;

          &:before {
            content:'';
            background-color:rgba(62,62,62,0.64);
            @apply block absolute right-full top-0 w-screen h-full;
          }

          h2, h1 {
            @apply relative text-4xl lg:text-5xl p-0 m-0 theme-c1 bebas;
          }

          .custom-pagination-intro {
            @apply absolute bottom-7 w-full flex justify-evenly pr-4 lg:pr-12 pl-4 lg:pl-0 z-10;
        
            .swiper-pagination-bullet {
              background-color:rgba(217,217,217,0.67);
              @apply rounded-none flex-1;
            }
        
            .swiper-pagination-bullet-active {
              @apply theme-b2;
            }
          }
        }

        .img-container {
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 0.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          transition-delay: 300ms;

          @apply opacity-0 translate-x-full;
        }
      }
      @apply absolute bottom-0 left-0 w-full;

    }

    .swiper-slide {
      @apply px-0;
    }
  }

  #guides-cards {
    .card {
      @apply shadow-none border-0 rounded-none grid-cols-2 gap-0 mb-0 items-center flex flex-col md:grid;

      &:hover {
        @apply shadow-none cursor-auto;
      }

      .image {
        @apply m-0 p-0 w-full;
      }

      .cardContent {
        @apply text-left p-0 md:p-8 lg:p-16 mt-8 mb-24 md:m-0;

        h3 {
          @apply text-4xl theme-c2 text-left mb-8 bebas;
        }
      }

      .card-btn {
        @apply inline-block theme-b2 bebas text-base theme-c1 border-0 mt-8;

        &:hover {
          @apply theme-b4;
        }
      }
      
    }
  }

  #riga-titolo {
    // @apply mt-32;
  }

  section#header-image {
    
  }

  #section-rates {
    @apply theme-b5 py-20;
  }

  table {
    border-color:var(--theme-color-2);
    @apply table-auto border-collapse w-full border-b-2;

    th {
      border-color:var(--theme-color-2);
      @apply text-left py-3 pr-6 border-b-2;
    }

    td {
      @apply py-3 pr-6;
    }
  }

  #section-1657557022,
  #section-1657609206 {
    border-color:var(--theme-color-2);
    @apply border-t-2 border-b-2 py-6;

    h3 {
      @apply m-0;
    }
  }

  .externalBtn {
    @apply theme-b2 py-3 px-6 text-white text-xl animated inline-flex items-center bebas;
      
      &:hover {
        @apply theme-b3 theme-c4;
  
        path {
          fill:var(--theme-color-4);
        }
      }
  
      svg {
        @apply ml-6 animated;
      }
  }
}

#header-image-slider {
  @apply pt-20 lg:pt-0;

  .swiper {
    .container {
      @apply py-0 pb-8 lg:py-12;
    }
  }
}

figure.table {
  @apply overflow-x-auto;
  table {
    td, th {
      @apply whitespace-nowrap;
    }
  }
}

.custom-pagination {

  &.swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply relative flex justify-center bottom-auto;

    .swiper-pagination-bullet
    {
      // min-width:100px;
      @apply rounded-none theme-b5 opacity-100 mx-3 md:mx-0 md:mr-6 flex-1;
    }

    .swiper-pagination-bullet-active {
      @apply theme-b2;
    }
  }
}

.link {
  @apply mt-3;
}




#diconodinoi {
  img {
    @apply rounded-full;
  }

  p {
    @apply text-left text-sm italic;
  }
}

/* FOOTER */

#footer {
  @apply text-center lg:text-left text-white overflow-hidden pb-16 text-sm theme-b4 px-8 pt-4;

  
  h5 {
    @apply text-4xl text-white font-normal bebas mb-4;
  }

  table {
    @apply flex justify-center lg:justify-start;
    td {
      @apply flex pr-4 pb-2 text-center justify-center;
    }
    
  }

  figure.table {
    @apply overflow-x-hidden;
    table {
      td, th {
        @apply whitespace-normal;
      }
    }
  }

  a {
    @apply theme-c1;

    &:hover {
      @apply theme-c2;
    }
  }

  .logo {
    @apply flex justify-center lg:justify-start;

    svg {
      @apply h-16 w-auto my-8 mx-auto md:mx-0;
    }

    path {
      // @apply fill-white;
      fill: var(--theme-color-2);
    }

  }

  .social {
    @apply flex justify-center lg:justify-start;

    path {
      @apply animated;
    }

    a{
      @apply mr-4;

      &:hover {
        path {
          fill: var(--theme-color-2);
        }
      }
    }
  }


}

#postfooter {
  @apply py-8 font-normal;
}

/* CASE HISTORY */

main.case-history {
  @apply py-32;

  .text {
    h1 {
      @apply text-center text-2xl md:text-4xl lg:text-5xl font-semibold theme-c4;

      &::after {
        content: '';
        @apply w-24 h-2 mx-auto block mt-2 theme-b2;
      }
    }
  }

  .card {
    @apply h-auto flex flex-col lg:flex-row justify-between rounded-none border-0 shadow-none hover:shadow-none hover:cursor-auto my-16;

    .image {
      @apply w-full lg:w-1/2;
    }

    .caption {
      @apply w-full lg:w-1/2 text-left;

      h3 {
        @apply text-3xl font-bold mb-8;
      }
    }

  }

  #case_history_cards {
    &>div:nth-child(odd) {
      .card {
        .image {
          @apply lg:order-1;
        }

        .caption {
          @apply lg:order-2;
        }
      }
    }

    &>div:nth-child(even) {
      .card {
        .image {
          @apply lg:order-2;
        }

        .caption {
          @apply lg:order-1;
        }
      }
    }
  }

}

/* PRIVACY */

main.page {

  @apply theme-c1;

  #riga1 {
    @apply pt-36 pb-24 theme-b1;

    h2 {
      @apply relative text-7xl p-0 m-0 font-normal theme-c4;

      &::after {
        content: '';
        @apply w-24 h-2 theme-b2 absolute -bottom-6 left-0;
      }
    }
  }

  #riga2 {
    @apply py-16 theme-b1 theme-c4;

    h2 {
      @apply text-3xl font-normal theme-c4 mt-12 mb-4;
    }

    h3 {
      @apply text-xl font-normal theme-c4 my-4;
    }
  }

  p {
    @apply my-3;
  }

  ul {
    @apply list-disc pl-6;
  }

  a {
    @apply theme-c2;
  }

}

/* CONTACT FORM */

.modal-cms {
  @apply justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none;

  &-container {
    min-width: 500px;
    @apply relative w-auto my-6 mx-auto max-w-4xl;

    &-inner {
      @apply border-0 shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white;
    }
  }

  &-header {
    @apply flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t;

    h3 {
      @apply text-3xl font-semibold theme-c4;
    }

    .closeBtn {
      @apply p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none;
    }
  }

  .modal-form-row {
    border-color: #d3d3d3;
    @apply border-b py-2 mb-8;
  }

  .required {
    @apply text-red-600;
  }

  .sendBtn {
    @apply animated theme-b2 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-2xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150;

    &:hover {
      @apply theme-b4 text-white;
    }
  }
}

#contact-form-row {
  @apply theme-b5 py-8;
}

.form-contact {
  .form-row {
    @apply mb-4;

    input, textarea {
      @apply border-b-2 border-solid border-white animated outline-none;

      &:focus {
        @apply  outline-none;
        border-color:var(--theme-color-2);
      }
    }
  }
}

/* autocomplete */
.autocomplete {
  @apply relative;

  ul.list {
    @apply list-none p-0 absolute w-full bg-white z-10 max-h-52 overflow-y-auto;

    li .item {
      @apply px-4 py-2 cursor-pointer;
      &.highlight {
        @apply text-white;
        background-color: var(--theme-color-2)
      }
    }
  }
}

.attachment {
  @apply pt-24;

  a.downloadBtn {
    @apply theme-b2 py-4 px-8 text-white text-2xl animated inline-flex items-center;
    
    &:hover {
      @apply theme-b3 theme-c4;

      path {
        fill:var(--theme-color-4);
      }
    }

    svg {
      @apply mr-6 animated;
    }
  }
}



.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* PROGRESS BAR */

#nprogress {
  .bar {
    .peg {
      @apply hidden;
    }
  }
}

/* MAP */
#map-row {
  background-color: var(--theme-color-2);
  color:#FFF;
  padding:2rem 0;

  h1,h2,h3,h4,h5,h6 {
    color:#FFF;
  }

  .text {
    display:flex;
    align-items: center;
    padding:2rem 0;

    a {
      text-decoration: underline;
    }
  }

  .leaflet-container {
    @apply aspect-square;
  }

  .griglia {
    @apply flex flex-col md:grid;

    & > div:first-of-type {
      @apply order-2 md:order-1;
    }
    & > div:nth-of-type(2) {
      @apply order-1 md:order-2;
    }
  }

}


/* MEDIAQUERIES */

@media (min-width: 768px) { 
  #footer table td {
    display:table-cell;
    text-align:left;
  }
}

@media (max-width: 768px) { 
  main.homepage {
    #riga1 {
      .videoContainer,
      .videoPlaceholder,
      video {      
        aspect-ratio: 1280/1280 !important;  
      }

      .videoContainer {
        .swiper-slide {
          aspect-ratio: 1280/1280 !important;  
        }
      }
    }
  }
}

@media (max-width: 1024px) { 
  
  .videoContainer {
    margin-top:80px;

    .container {
      max-width: 100% !important;
    }
  }

  main.homepage {
    #riga1 {
      .videoContainer,
      .videoPlaceholder,
      video {      
        aspect-ratio: 1280/720;  
        @apply h-auto;
      }

      .videoPlaceholder {
        margin-top:80px;

        .social {
          @apply flex flex-row;
          top:1rem;
          right:0;

          a {
            margin-bottom:0;
            margin-left:1rem;
          }
        }
      }

      .videoContainer {
        .swiper-slide {
          aspect-ratio: 1280/720;  
          @apply h-auto;
        }
      }

      .swiper-slide {
        .captionContainer {
          max-width: 100% !important;
          padding-right:0 !important;
          padding-left:0 !important;
      
          .caption {
            min-height:auto;
            padding-top:1rem;
            padding-bottom:1.5rem;
          }
        }
      }
      .custom-pagination {
        bottom:0.5rem;
      }
      
    }
  }
}

@media (max-width: 1280px) { 
  
}

.container {
  @apply px-6;
}